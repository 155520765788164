<template>
  <div class="p-pegas">
    <div
      class="card-table"
      :class="mode ? 'card__tableday' : 'card__tablenight'"
    >
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="17">
            <div class="caz-blocks-sarcho-title">
              <div
                :class="mode ? 'content__titleday' : 'content__titlenight'"
                class="content-title d-flex align-center mr-2"
              >
                {{ $t("message.journal") }}
              </div>
              <div class="block-sarche">
                <div class="header__search my__header__search d-flex">
                  <select-staff
                    :id="filterForm.staff_id"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.staff_id"
                  ></select-staff>
                  <el-date-picker
                    v-model="filterForm.punch_time_from"
                    :class="mode ? 'input__day' : 'input__night'"
                    type="date"
                    :placeholder="$t('message.from')"
                    size="small"
                    :format="'dd.MM.yyyy'"
                    :value-format="'dd.MM.yyyy'"
                  >
                  </el-date-picker>
                  <el-date-picker
                    v-model="filterForm.punch_time_until"
                    :class="mode ? 'input__day' : 'input__night'"
                    type="date"
                    :placeholder="$t('message.before')"
                    size="small"
                    :format="'dd.MM.yyyy'"
                    :value-format="'dd.MM.yyyy'"
                  >
                  </el-date-picker>
                  <el-button
                    :size="'small'"
                    type="primary"
                    icon="el-icon-refresh-right"
                    @click="refresh()"
                    >{{ $t("message.refresh") }}</el-button
                  >
                </div>
              </div>
            </div>
          </el-col>
          <el-col
            :xs="24"
            :sm="3"
            class="flex-style text-right journal__mobile"
            style=" height: 30px;
          display: flex;
          align-items: center;
          justify-content: flex-end;"
          >
            <div
              :class="mode ? 'content__titleday' : 'content__titlenight'"
              class="data-hr m-0"
            >
              <i class="el-icon-date"></i>
              {{ currentTime }}
            </div>
          </el-col>
          <el-col
            :xs="24"
            :sm="4"
            style="height: 30px; display: flex;
          align-items: center;
          justify-content: flex-end;"
          >
            <span v-can="'attendances.excel'">
              <export-excel
                v-if="list.length"
                :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                class="btn excel_btn"
                :data="excel_datas"
                worksheet="My Worksheet"
                name="Журнал.xls"
                :fields="excel_fields"
                @fetch="controlExcelData()"
                style="display: flex;
                    align-items: center;
                    margin-right: 10px;"
              >
                <el-button size="mini">
                  <i class="el-icon-document-delete"></i>
                  {{ $t("message.excel") }}
                </el-button>
              </export-excel>
            </span>
            <crm-create-and-column-settings
              :permission="$options.name"
              @c-create="drawerCreate = true"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              :columns="columns"
              :v_can_create="'attendances.create'"
              @c-change="updateColumn"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table
          class="table-my-code table-bordered td-center"
          :class="mode ? 'table__myday' : 'table__mynight'"
          v-loading="loadingData"
        >
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>
              <th v-if="columns.staff_full_name.show">
                {{ columns.staff_full_name.title }}
              </th>
              <th v-if="columns.department.show">
                {{ columns.department.title }}
              </th>
              <th v-if="columns.branch.show">
                {{ columns.branch.title }}
              </th>
              <th v-if="columns.company.show">
                {{ columns.company.title }}
              </th>
              <th v-if="columns.punch_time.show">
                {{ columns.punch_time.title }}
              </th>
              <th v-if="columns.punch_state.show">
                {{ columns.punch_state.title }}
              </th>
              <th v-if="columns.img.show">
                {{ columns.img.title }}
              </th>
              <!-- <th v-if="columns.verify_type.show">
                {{ columns.verify_type.title }}
              </th> -->
              <th v-if="columns.device.show">
                {{ columns.device.title }}
              </th>
              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>
            </tr>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.id"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>
              <th v-if="columns.staff_full_name.show"></th>
              <th v-if="columns.department.show">
                <select-department
                  :id="filterForm.department_id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.department_id"
                ></select-department>
              </th>
              <th v-if="columns.branch.show">
                <select-branch
                  :id="filterForm.branch_id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.branch_id"
                ></select-branch>
              </th>
              <th v-if="columns.company.show">
                <select-company
                  :id="filterForm.company_id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.company_id"
                ></select-company>
              </th>
              <th v-if="columns.punch_time.show">
                <el-date-picker
                  v-model="filterForm.punch_time"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  type="date"
                  :placeholder="$t('message.before')"
                  size="small"
                  :format="'dd.MM.yyyy'"
                  :value-format="'dd.MM.yyyy'"
                >
                </el-date-picker>
              </th>
              <th v-if="columns.punch_state.show">
                <el-select
                  :placeholder="columns.punch_state.title"
                  class="d-block"
                  v-model="filterForm.punch_state"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :size="'small'"
                  filterable
                  clearable
                >
                  <el-option :label="'Выход'" value="1"></el-option>
                  <el-option :label="'Вход'" value="'0'"></el-option>
                </el-select>
              </th>

              <th v-if="columns.img.show"></th>
              <!-- <th v-if="columns.verify_type.show">
                {{ columns.verify_type.title }}
              </th> -->
              <th v-if="columns.device.show">
                <select-device
                  :id="filterForm.device_serail_num"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.device_serail_num"
                ></select-device>
              </th>
              <th v-if="columns.created_at.show">
                <crm-date-picker
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                ></crm-date-picker>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="att in list" :key="'atts-' + att.id">
              <td v-if="columns.id.show">{{ att.id }}</td>
              <td
                v-if="columns.staff_full_name.show"
                @dblclick="profile(att.staff)"
              >
                {{
                  att.staff ? att.staff.name + " " + att.staff.last_name : ""
                }}
              </td>
              <td v-if="columns.department.show">
                {{
                  att.staff
                    ? att.staff.department
                      ? att.staff.department.name
                      : ""
                    : ""
                }}
              </td>
              <td v-if="columns.branch.show">
                {{
                  att.staff
                    ? att.staff.branch
                      ? att.staff.branch.name
                      : ""
                    : ""
                }}
              </td>
              <td v-if="columns.company.show">
                {{
                  att.staff
                    ? att.staff.company
                      ? att.staff.company.name
                      : ""
                    : ""
                }}
              </td>
              <td
                :class="mode ? 'td-success' : ''"
                v-if="columns.punch_time.show"
              >
                <el-button type="success" size="mini" icon="el-icon-time" round>
                  {{ att.punch_time }}
                </el-button>
              </td>
              <td v-if="columns.punch_state.show">{{ att.punch_state }}</td>
              <td v-if="columns.img.show">
                <el-image
                  style="width: 40px; height: 40px"
                  :src="
                    att.captured_img
                      ? baseUrl + att.captured_img
                      : 'img/avatar.jpeg'
                  "
                  :preview-src-list="previewList"
                >
                </el-image>
              </td>
              <td v-if="columns.device.show">
                {{
                  att.device_two && att.device_two.device_name
                    ? att.device_two.device_name
                    : $t("message.manual")
                }}
              </td>
              <td v-if="columns.created_at.show">{{ att.created_at }}</td>
            </tr>
          </tbody>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>
    </div>

    <div class="app-modal app-modal__full">
      <el-drawer
        :with-header="false"
        :visible.sync="drawerCreate"
        size="80%"
        ref="drawerCreate"
        @opened="drawerOpened('drawerCreateChild')"
        @closed="drawerClosed('drawerCreateChild')"
      >
        <crm-create ref="drawerCreateChild" drawer="drawerCreate"></crm-create>
      </el-drawer>

      <el-drawer
        :with-header="false"
        :visible.sync="drawerUpdate"
        size="80%"
        ref="drawerUpdate"
        @opened="drawerOpened('drawerUpdateChild')"
        @closed="drawerClosed('drawerUpdateChild')"
      >
        <crm-update
          :selectedItem="selectedItem"
          ref="drawerUpdateChild"
          drawer="drawerUpdate"
        ></crm-update>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import selectDepartment from "@/components/filters/inventory/select-department";
import selectCompany from "@/components/filters/inventory/select-company";
import selectBranch from "@/components/filters/inventory/select-branch";
import selectDevice from "@/components/filters/inventory/select-device-manual";
import moment from "moment";
import list from "@/utils/mixins/list";
import selectStaff from "@/components/filters/inventory/select-staff";
import Pagination from "@/components/pagination";
import CrmCreate from "./components/crm-create";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  mixins: [list],
  name: "journal",
  components: {
    selectStaff,
    selectDevice,
    selectCompany,
    selectBranch,
    selectDepartment,
    Pagination,
    CrmCreate
  },
  data() {
    return {
      currentTime: moment().format("DD.MM.YYYY HH:mm"),
      baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 3
      )
    };
  },
  created() {
    setInterval(() => this.updateCurrentTime(), 1000);
  },
  mounted() {
    if (_.isFunction(this.controlExcelData)) {
      this.controlExcelData();
    }
  },
  controlExcelData() {
    this.excel_fields = {};
    for (let key in this.columns) {
      if (this.columns.hasOwnProperty(key)) {
        let column = this.columns[key];
        if (
          column.show &&
          column.column !== "settings" &&
          column.column !== "img" &&
          column.column !== "updated_at"
        ) {
          this.excel_fields[column.title] = column.column;
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      list: "attendance/list",
      excel_datas: "attendance/excel_data",
      columns: "attendance/columns",
      pagination: "attendance/pagination",
      statues: "attendance/statues",
      filter: "attendance/filter",
      sort: "attendance/sort",
      mode: "MODE"
    }),
    previewList: function() {
      let baseUrl = this.baseUrl;
      function addBaseUrl(att) {
        return baseUrl + "" + att.captured_img;
      }
      return _.map(this.list, addBaseUrl);
    },
    actions: function() {
      return [];
    }
  },
  methods: {
    ...mapActions({
      updateList: "attendance/index",
      setPagination: "attendance/setPagination",
      updateSort: "attendance/updateSort",
      updateFilter: "attendance/updateFilter",
      updateColumn: "attendance/updateColumn",
      updatePagination: "attendance/updatePagination",
      show: "attendance/show",
      empty: "attendance/empty",
      delete: "attendance/destroy",
      refreshData: "attendance/refreshData"
    }),
    updateCurrentTime() {
      this.currentTime = this.getCurrentTime();
    },
    getCurrentTime() {
      return moment().format("DD.MM.YYYY HH:mm");
    },
    openDrawer() {
      this.appModal = !this.appModal;
    },
    profile(model) {
      let route = this.$router.resolve({
        name: "profile",
        params: {
          id: model.id
        }
      });

      window.open(route.href, "_blank");

      //    OLD CODE
      //   this.$router.push({
      //     name: "profile",
      //     params: { id: model.id },
      //   });
    }
  }
};
</script>
